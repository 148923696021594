import React from 'react';
import { Link as RouterLink } from 'react-router';
import type { LinkProps as RouterLinkProps } from 'react-router';
import styled, { css } from 'styled-components';
import useTracking, { TrackingEventData } from '../../../hooks/useTracking';
import { FOCUS_OUTLINE } from '../../../theme';
import { EventNames } from '../../../utils/trackingEvents';
import {
  ButtonCSS,
  ButtonSize,
  ButtonVariant,
  SIZE_TEXT_PROPS,
} from '../Button';
import Text from '../Text';

export enum LinkDecoration {
  None = 'None',
  Hover = 'Hover',
  All = 'All',
}

export const LinkCSS = css<{
  $size?: ButtonSize;
  $variant?: ButtonVariant;
  $fullWidth?: boolean;
  $isError?: boolean;
  $decoration: LinkDecoration;
}>`
  display: inline-flex;
  align-items: center;
  gap: 8px;

  cursor: pointer;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;

  background: none;
  border: none;

  text-decoration: none;

  border-radius: 4px;
  &:focus-visible {
    outline-offset: 2px;
    outline: ${FOCUS_OUTLINE};
  }

  ${({ $decoration }) => {
    switch ($decoration) {
      case LinkDecoration.None:
        return css`
          text-decoration: none;
        `;
      case LinkDecoration.Hover:
        return css`
          & > * {
            text-decoration: none;
          }
          &:hover > * {
            text-decoration: underline;
          }
        `;
      case LinkDecoration.All:
      default:
        return css`
          text-decoration: underline;
          & > p {
            text-decoration: underline;
          }
        `;
    }
  }}

  ${({ $size, $variant, $fullWidth }) =>
    ($size || $variant || $fullWidth) && ButtonCSS}
`;

const StyledRouterLink = styled(RouterLink)<{
  $size?: ButtonSize;
  $variant?: ButtonVariant;
  $fullWidth?: boolean;
  $decoration: LinkDecoration;
}>`
  ${LinkCSS}
`;

const StyledNativeLink = styled.a<{
  $size?: ButtonSize;
  $variant?: ButtonVariant;
  $fullWidth?: boolean;
  $decoration: LinkDecoration;
}>`
  ${LinkCSS}
`;

interface LinkProps extends RouterLinkProps, React.PropsWithChildren {
  testId?: string;
  buttonSize?: ButtonSize;
  buttonVariant?: ButtonVariant;
  buttonFullWidth?: boolean;
  nativeLink?: boolean;
  decoration?: LinkDecoration;
  trackingData?: TrackingEventData & { eventName?: EventNames };
}

export const Link: React.FC<LinkProps> = ({
  replace,
  to,
  children,
  onClick,
  testId,
  target,
  buttonSize,
  buttonVariant,
  buttonFullWidth = false,
  nativeLink = false,
  decoration = LinkDecoration.All,
  trackingData,
}) => {
  const { track } = useTracking();

  const styleLikeButton = buttonSize || buttonVariant || buttonFullWidth;

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      onClick(e);
    }
    if (trackingData) {
      const { eventName, ...restTrackingData } = trackingData;
      track(eventName ?? EventNames.LinkClick, restTrackingData);
    }
  };

  const childText = () => {
    if (!children) {
      return null;
    }

    return (
      <Text
        component="span"
        {...SIZE_TEXT_PROPS[buttonSize || ButtonSize.Medium]}
        data-testid={`${testId}-children`}
      >
        {children}
      </Text>
    );
  };

  if (nativeLink) {
    return (
      <StyledNativeLink
        href={to as string}
        onClick={handleClick}
        data-testid={testId}
        target={target}
        $size={buttonSize}
        $variant={buttonVariant}
        $fullWidth={buttonFullWidth}
        $decoration={decoration}
      >
        {styleLikeButton ? childText() : children}
      </StyledNativeLink>
    );
  }

  return (
    <StyledRouterLink
      replace={replace}
      to={to}
      onClick={handleClick}
      data-testid={testId}
      target={target}
      $size={buttonSize}
      $variant={buttonVariant}
      $fullWidth={buttonFullWidth}
      $decoration={decoration}
    >
      {styleLikeButton ? childText() : children}
    </StyledRouterLink>
  );
};
