import { FieldFunctionOptions } from '@apollo/client';
import { Reference, relayStylePagination } from '@apollo/client/utilities';
import { getCurrentToken } from '@teamexos/fit-shared';
import type { ClientOptions } from '@teamexos/prince-sdk';
import EventEmitter from 'eventemitter3';
import fragments from '../graphql/fragments.json';
import { EnvLevel, getEnvLevel } from '../utils/getEnvLevel';

const clientEmitter = new EventEmitter();
const CLIENT_RECONNECTED = 'ws-reconnected';

export const subscribeToReconnect = (cb: () => void) => {
  clientEmitter.addListener(CLIENT_RECONNECTED, cb);

  return () => {
    clientEmitter.removeListener(CLIENT_RECONNECTED, cb);
  };
};

export const mergeNodes = (existing: Reference[], incoming: Reference[]) => {
  if (!existing) {
    return incoming;
  }

  if (!incoming) {
    return existing;
  }

  const existingRefs = existing.map(({ __ref }) => __ref);

  // dedupe any incoming and preserve order
  const incomingDeduped = incoming.filter(
    ({ __ref }) => !existingRefs.includes(__ref),
  );

  return [...existing, ...incomingDeduped];
};

export const mergeEdges = (
  existing: Array<{ node: Reference }>,
  incoming: Array<{ node: Reference }>,
  options?: Partial<
    FieldFunctionOptions<Record<string, any>, Record<string, any>>
  >,
  inputVariableOverride?: string,
) => {
  const variables = options?.variables;
  const input = variables?.[inputVariableOverride ?? 'input'];
  if (!input?.after) {
    // check for oddly named input variables and warn if they weren't checked
    if (!input && getEnvLevel() === EnvLevel.DEV) {
      const otherInputVariable = Object.keys(variables ?? {}).find(
        (key) => key.toLocaleLowerCase().includes('input') && key !== 'input',
      );
      if (otherInputVariable) {
        // eslint-disable-next-line no-console
        console.warn(
          `Not merging edges because this request is not paginating; however "${otherInputVariable}" was found.
          Pagination for this request may not work as expected.`,
        );
      }
    }

    // we only want to merge nodes if is pagination
    // otherwise we want the incoming, because filters may change
    return incoming;
  }

  if (!existing) {
    return incoming;
  }

  if (!incoming) {
    return existing;
  }

  const existingRefs = existing.map(({ node: { __ref } }) => __ref);

  // dedupe any incoming chats and preserve order
  const incomingDeduped = incoming.filter(
    ({ node: { __ref } }) => !existingRefs.includes(__ref),
  );

  return [...existing, ...incomingDeduped];
};

export const clientOptions: ClientOptions = {
  getToken: async () => {
    const tokens = await getCurrentToken();

    return tokens?.idToken?.toString();
  },
  clientUrl: process.env.GATSBY_API_URL,
  wsUrl: process.env.GATSBY_WS_URL,
  onWsReconnected: () => {
    clientEmitter.emit(CLIENT_RECONNECTED);
  },
  apolloClientOptions: {
    name: 'coach-hub-web',
    version: process.env.GATSBY_COMMIT_ID ?? 'unknown',
    connectToDevTools: process.env.NODE_ENV === 'development',
  },
  possibleTypes: fragments.possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        usePush: {
          read: (existing: boolean | undefined) =>
            typeof existing === 'boolean' ? existing : true,
        },
        contents: {
          merge: mergeEdges,
        },
      },
    },
    User: {
      fields: {
        chatRooms: relayStylePagination(['filters', 'orderBy']),
        activity: relayStylePagination(['filters']),
        userActivity: relayStylePagination(['filters']),
        sessions: relayStylePagination(['orderBy', 'filter', 'dateFilter']),
        notes: relayStylePagination(),
        currentPlan: {
          merge: true,
        },
        coachData: {
          merge: true,
        },
      },
    },
    UserAssessmentSurvey: {
      keyFields: false,
      fields: {
        assignments: relayStylePagination(['filter']),
      },
    },
    ActivityConnection: {
      fields: {
        nodes: {
          merge: (existing, incoming, options) => {
            // we only want to merge nodes if is pagination
            // otherwise we want the incoming, because filters may change
            if (!options?.variables?.input?.after) {
              return incoming;
            }

            return mergeNodes(existing, incoming);
          },
        },
      },
    },
    UserNotesConnection: {
      fields: {
        edges: {
          merge: mergeEdges,
        },
      },
    },
    UserActivityConnection: {
      fields: {
        edges: {
          merge: mergeEdges,
        },
      },
    },
    ChatRoomUsersConnection: {
      fields: {
        edges: {
          merge: mergeEdges,
        },
      },
    },
    UserChatRoomsConnection: {
      fields: {
        edges: {
          merge: mergeEdges,
        },
      },
    },
    UserAssessmentAssignmentsConnection: {
      fields: {
        edges: {
          merge: (existing, incoming, options) =>
            mergeEdges(
              existing,
              incoming,
              options,
              'assessmentAssignmentsInput',
            ),
        },
      },
    },
    ChatRoom: {
      fields: {
        messages: relayStylePagination(),
      },
    },
    CoachUsersConnection: {
      fields: {
        edges: {
          merge: mergeEdges,
        },
      },
    },
    CoachTouchPointConnection: {
      fields: {
        edges: {
          merge: mergeEdges,
        },
      },
    },
    CoachData: {
      fields: {
        coachUsers: relayStylePagination(['filter', 'orderBy']),
        touchPoints: relayStylePagination(['filter']),
        sessions: relayStylePagination(['filter', 'orderBy']),
      },
    },
    UserCoachSessionsConnection: {
      fields: {
        edges: {
          merge: mergeEdges,
        },
      },
    },
    Plan: {
      fields: {
        planItems: relayStylePagination(['filter']),
      },
    },
    ChatRoomMessagesConnection: {
      fields: {
        edges: {
          merge(
            existing: { node: Reference }[],
            incoming: { node: Reference }[],
            { readField },
          ) {
            const edges: Record<string, { node: Reference }> = {};

            (existing ?? []).forEach((r) => {
              const key = readField('id', r.node);
              if (typeof key === 'string' || typeof key === 'number') {
                edges[key] = r;
              }
            });
            (incoming ?? []).forEach((r) => {
              const key = readField('id', r.node);
              if (typeof key === 'string') {
                edges[key] = r;
              }
            });

            // Sort messages by id
            return Object.values(edges).sort(
              (a, b) =>
                Number(readField('id', a.node) ?? '') -
                (Number(readField('id', b.node)) ?? ''),
            );
          },
        },
      },
    },
    PlanItemConnection: {
      fields: {
        edges: {
          merge: (existing, incoming, options) =>
            mergeEdges(existing, incoming, options, 'planItemsInput'),
        },
      },
    },
  },
  debug: process.env.NODE_ENV === 'development',
};
