export enum ScreenNames {
  HomePage = 'Home View',
  MemberList = 'Member List View',
  MemberProfile = 'Member Profile View',
  Unknown = 'Unknown',
  SignInEmail = 'Sign In Email',
  SignInPassword = 'Sign In Password', // pragma: allowlist secret
  SessionsView = 'Sessions View',
  Availability = 'Availability',
  Preferences = 'Preferences',
  Connections = 'Connections',
  CreateGameplan = 'Creating New Gameplan Phase',
  EditGameplan = 'Editing Gameplan Phase',
  ViewGameplan = 'Viewing Gameplan Phase',
  Methodology = 'Methodology Bot',
}

export enum EventInstances {
  Fit = 'Fit',
}

export enum EventCategories {
  Sessions = 'Sessions',
  Chat = 'Chat',
  Notes = 'Notes',
  Members = 'Members',
  Resources = 'Resources',
  SignIn = 'Sign In',
  Tasks = 'Tasks',
  Navigation = 'Navigation',
  Settings = 'Settings',
  Profile = 'Profile',
  Goals = 'Goals',
  Assessments = 'Assessments',
  Activity = 'Activity',
  CustomPlan = 'Custom Plan',
  Content = 'Content',
  Chatbot = 'Chatbot',
  Home = 'Home',
}

export enum EventSubCategories {
  Collapse = 'Collapse',
  Expand = 'Expand',
  Remove = 'Remove',
  Add = 'Add',
  Information = 'Information',
  On = 'On',
  Off = 'Off',
  Image = 'Image',
  Template = 'Template',
  Coach = 'Coach',
  Type = 'Type',
  Attendance = 'Attendance',
  Attendances = 'Attendances',
  DateRange = 'Date Range',
  SessionTypes = 'Session Types',
  CoachSessionStyle = 'Coach Session Style',
  CoachExperienceType = 'Coach Experience Type',
  MemberName = 'Member Name',
  Filters = 'Filters',
  ChatList = 'Chat List',
  NotesList = 'Notes List',
  Assign = 'Assign',
  Complete = 'Complete',
  AddActivity = 'Add Activity',
  Preview = 'Preview',
  Emojis = 'Emojis',
  MemberNote = 'Member Note',
  Body = 'Body',
  Title = 'Title',
}

export enum EventLabels {
  Attended = 'Attended',
  NoShow = 'No Show',
  ExcusedAbsence = 'Excused Absence',
  UnableToLoadMessages = 'Unable to load messages',
  UnableToLoadOldMessages = 'Unable to load old messages',
  All = 'All',
  New = 'New',
  Starred = 'Starred',
  UnreadMessages = 'Unread Messages',
  Cancel = 'Cancel',
  Save = 'Save',
  YesCancel = 'Yes, Cancel',
  Nevermind = 'Nevermind',
  LogIn = 'Log In',
  Continue = 'Continue',
  Password = 'Password', // pragma: allowlist secret
  Email = 'Email',
  Title = 'Title',
  Description = 'Description',
  DueDate = 'Due Date',
  Member = 'Member',
  Home = 'Home',
  Members = 'Members',
  SessionsTab = 'Sessions',
  JoinSession = 'Join Session',
  Filter = 'Filter',
  SessionsToday = 'Sessions Today',
  NeedAttendance = 'Need Attendance',
  Apply = 'Apply',
  Last45days = 'Last 45 days',
  Last7days = 'Last 7 days',
  Today = 'Today',
  Next7days = 'Next 7 days',
  Next45days = 'Next 45 days',
  Reset = 'Reset',
  SessionBuffer = 'Session Buffer',
  GetStarted = 'Get Started',
  SetUpConnections = 'Set Up Connections',
  Reconnect = 'Reconnect',
  FixConnections = 'Fix Connections',
  TimeZone = 'Time Zone',
  DailySessionLimit = 'Daily Session Limit',
  BookSession = 'Book Session',
  RescheduleSession = 'Reschedule Session',
  CancelSession = 'Cancel Session',
  Coach = 'Coach',
  SessionType = 'Session Type',
  Location = 'Location',
  Reschedule = 'Reschedule',
  SubCategory = 'Sub-category',
  Assign = 'Assign',
  Add = 'Add',
  Remove = 'Remove',
  Complete = 'Complete',
  GotIt = 'Got it',
  ViewResults = 'View Results',
  Create = 'Create',
  Back = 'Back',
  Edit = 'Edit',
  Duplicate = 'Duplicate',
  RemoveAll = 'Remove All',
  CopyTo = 'Copy to',
  UpdateAvailability = 'Update Availability',
  ReportExternalSession = 'Report External Session',
  OpenContentLibrary = 'Open Content Library',
  Methodology = 'Methodology Bot',
  Preview = 'Preview',
  Select = 'Select',
  InitialMessage = 'Initial Message',
  CreateChat = 'Create Chat',
  UnitPreference = 'Unit Preference',
}

export enum EventSectionNames {
  InPersonAvailability = 'In-Person Availability',
  Attendance = 'Attendance',
  SessionType = 'Session Type',
  SessionStyle = 'Session Style',
  General = 'General',
  Status = 'Status',
  CoachingExperienceType = 'CoachingExperienceType',
  Sessions = 'Sessions',
  RecentActivity = 'Recent Activity',
  Clients = 'Clients',
  CalendarAvailability = 'Calendar Availability',
  CalendarAccounts = 'Calendar Accounts',
  GettingSetUp = 'Getting Set Up',
  VideoConferencingTools = 'Video Conferencing Tools',
  FloatingAlert = 'Floating Alert',
  Chat = 'Chat',
  Units = 'Units',
}

export enum EventNames {
  ScreenView = 'Screen View',
  ProgramAdd = 'Program Add',
  ProgramEdit = 'Program Edit',
  ProgramSelectionSaveContinued = 'Program Selection Save Continued',
  ProgramSelectionSaveCancelled = 'Program Selection Save Cancelled',
  ProgramSelect = 'Program Select',
  ProgramSelectionSave = 'Program Selection Save',
  ProgramCardShowLess = 'Program Card Show Less',
  ProgramCardShowMore = 'Program Card Show More',
  InformationIconClick = 'Information Icon Click',
  PracticeCardShowLess = 'Practice Card Show Less',
  PracticeCardShowMore = 'Practice Card Show More',
  ProfileCardShowLess = 'Profile Card Show Less',
  ProfileCardShowMore = 'Profile Card Show More',
  PracticeEditSelect = 'Practice Edit Select',
  PracticeModalShowMore = 'Practice Modal Show More',
  PracticeModalShowLess = 'Practice Modal Show Less',
  PracticeCardSelectionSave = 'Practice Card Selection Save',
  PracticeCardSelectionSaveContinued = 'Practice Card Selection Save Continued',
  PracticeCardSelect = 'Practice Card Select',
  PracticeCardSelectionSaveCancel = 'Practice Card Selection Save Cancel',
  StarMember = 'Star Member',
  UnstarMember = 'Unstar Member',
  AssessmentModalShowMore = 'Assessment Modal Show More',
  AssessmentModalShowLess = 'Assessment Modal Show Less',
  BackButtonClick = 'Back Button Click',
  TouchPointLoadMore = 'Touch Point Load More',
  TouchPointSnackbarUndo = 'Snackbar Undo',
  OpenSurveyClick = 'Open Survey Click',
  CloseClick = 'Close Click',
  TapOut = 'Tap Out',
  LoadMore = 'Load More',
  CaretClick = 'Caret Click',
  FilterClick = 'Filter Click',
  FilterItemSelect = 'Filter Item Select',
  ResetAll = 'Reset All',
  ThreeDotMenuClick = 'Three Dot Menu Click',
  ThreeDotMenuAbandon = 'Three Dot Menu Abandon',
  MarkAttendance = 'Mark Attendance',
  SearchActivated = 'Search Activated',
  SearchInitiated = 'Search Initiated',
  SearchCleared = 'Search Cleared',
  Feedback = 'Feedback',
  ExpansionClick = 'Expansion Click',
  LinkClick = 'Link Click',
  ChatIconClick = 'Chat Icon Click',
  NotesIconClick = 'Notes Icon Click',
  NotesListClick = 'List Click',
  NotesListCreateClick = 'Plus Icon Click',
  LoadMoreData = 'Load More Data',
  Send = 'Send',
  PlusIconClick = 'Plus Icon Click',
  SchedulingIconClick = 'Scheduling Icon Click',
  MenuItemSelect = 'Menu Item Select',
  SeeMoreClick = 'See More Click',
  SeeLessClick = 'See Less Click',
  TemplateSelect = 'Template Select',
  TapBack = 'Tap Back',
  RemoveAttachment = 'Remove Attachment',
  ImageClick = 'Image Click',
  FileSelection = 'File Selection',
  RetryClick = 'Retry Click',
  NewMessageButtonClick = 'New Message Button Click',
  Save = 'Save',
  CancelEdit = 'Cancel Edit',
  TextBoxActivated = 'Text Box Activated',
  CloseModal = 'Close Modal',
  TileClick = 'Tile Click',
  EditClick = 'Edit Click',
  AddIconClick = 'Add Icon Click',
  SubtractIconClick = 'Subtract Icon Click',
  ButtonClick = 'Button Click',
  RemoveAllSessionsClick = 'Remove All Sessions Click',
  ForgotPassword = 'Forgot Password', // pragma: allowlist secret
  AddTask = 'Add Task',
  DropDownMenuClick = 'Drop Down Menu Click',
  DropDownMenuFocus = 'Drop Down Menu Focus',
  DropDownMenuItemClick = 'Drop Down Menu Item Click',
  DropDownMenuItemClear = 'Drop Down Menu Item Clear',
  DatePickerClick = 'Date Picker Click',
  Sort = 'Sort',
  MarkComplete = 'Mark Complete',
  MarkIncomplete = 'Mark Incomplete',
  MemberClick = 'Member Click',
  NavTabClick = 'Nav Tab Click',
  ClearAll = 'Clear All',
  RemoveTag = 'Remove Tag',
  CalendarIconClick = 'Calendar Icon Click',
  AvatarClick = 'Avatar Click',
  CheckboxClick = 'Checkbox Click',
  RadioClick = 'Radio Click',
  ToggleClick = 'Toggle Click',
  AddSession = 'Add Session',
  ListClick = 'List Click',
  DropDownMenuCleared = 'Drop Down Menu Cleared',
  DragAndDrop = 'Drag And Drop',
  ModalBackDropClick = 'Modal Back Drop Click',
  ModalEscapeKeyPress = 'Modal Escape Key Press',
  ItemSelect = 'Item Select',
  Play = 'Play',
  Pause = 'Pause',
  Stop = 'Stop',
  Seek = 'Seek',
  Mute = 'Mute',
  Unmute = 'Unmute',
  Fullscreen = 'Fullscreen',
  ExitFullscreen = 'Exit Fullscreen',
  EmojiIconClick = 'Emoji Icon Click',
}

export enum EventProfileCardNames {
  SessionCard = 'Sessions Card',
  SessionBanner = 'Session Banner',
  PTInfoModal = 'Personal Training Information Modal',
  PTSessionsModal = 'Personal Training Sessions Modal',
  PTCard = 'Personal Training Card',
  CancelConfirmModal = 'Cancel Confirmation Modal',
  SaveConfirmModal = 'Save Confirmation Modal',
  ReduceSessionsConfirmModal = 'Reduce Sessions Confirmation Modal',
  RemoveSessionsConfirmModal = 'Remove Sessions Confirmation Modal',
  Overdue = 'Overdue',
  Today = 'Today',
  Upcoming = 'Upcoming',
  AddTaskModal = 'Add Task Modal',
  EditTaskModal = 'Edit Task Modal',
  DateRangeModal = 'Date Range Modal',
  AddSessionModal = 'Add Session Modal',
  RescheduleSessionModal = 'Reschedule Session Modal',
  MemberGoal = 'Member Goal',
  GoalModal = 'Goal Modal',
  JoinSessionBanner = 'Join Session Banner',
  AssignAssessmentsModal = 'Assign Assessments Modal',
  CompleteAssessmentModal = 'Complete Assessment Modal',
  AssignedAssessments = 'Assigned Assessments',
  RemoveConfirmationModal = 'Remove Confirmation Modal',
  Assessments = 'Assessments',
  Feed = 'Feed',
  GameplanPhases = 'Gameplan Phases',
  AddActivityModal = 'Add Activity Modal',
  EditActivityModal = 'Edit Activity Modal',
  CurrentGameplan = 'Current Gameplan',
  Activity = 'Activity',
  QualtricsSurveyModal = 'Qualtrics Survey Modal',
  ContentLibraryModal = 'Content Library Modal',
  ContentPreviewModal = 'Content Preview Modal',
  CreateChat = 'Create Chat',
}
