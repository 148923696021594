import React, {
  AriaAttributes,
  FC,
  MouseEvent,
  ReactElement,
  ReactNode,
  useState,
} from 'react';
import {
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuProps,
} from '@mui/material';
import noop from 'lodash/noop';
import styled from 'styled-components';
import useTracking, { TrackingEventData } from '../../../hooks/useTracking';
import { BOX_SHADOW_2, GRAY200, HOVER } from '../../../theme';
import { EventNames } from '../../../utils/trackingEvents';
import { ButtonSize } from '../Button';
import Text, { TextColor, TextKind } from '../Text';
import Tooltip from '../Tooltip';

export const FlyoutDivider = styled(Divider)`
  margin: 0;
`;

export const FlyoutItem: FC<{
  onClick: (e: React.MouseEvent<HTMLLIElement>) => void;
  children?: React.ReactNode;
  testId?: string;
  // handleCloseMenu is a prop provided by Flyout component which is used to close the menu when an item is clicked
  handleCloseMenu?: () => void;
  textKind?: TextKind;
  startIcon?: ReactNode;
  disabled?: boolean;
  trackingData?: TrackingEventData;
}> = ({
  children,
  onClick,
  testId,
  handleCloseMenu,
  textKind = TextKind.BodyM,
  startIcon,
  disabled,
  trackingData,
}) => {
  const { track } = useTracking();
  const handleClick = (e: React.MouseEvent<HTMLLIElement>) => {
    onClick(e);
    if (trackingData) {
      track(EventNames.MenuItemSelect, trackingData);
    }
    if (handleCloseMenu) {
      handleCloseMenu();
    }
  };
  return (
    <MenuItem
      onClick={handleClick}
      data-testid={testId}
      disabled={disabled}
      sx={{ padding: '8px' }}
    >
      {startIcon && (
        <ListItemIcon
          style={{
            minWidth: 'unset',
            marginRight: '8px',
          }}
        >
          {startIcon}
        </ListItemIcon>
      )}
      <Text kind={textKind}>{children}</Text>
    </MenuItem>
  );
};

const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    box-shadow: ${BOX_SHADOW_2};
    border: 1px solid ${GRAY200};
  }
  & .MuiList-padding {
    padding: 2px 0;
  }
  & .MuiListItem-button:hover {
    background-color: ${HOVER};
  }
`;

interface FlyoutProps {
  flyoutId: string;
  trigger?: ReactElement<
    AriaAttributes & {
      onClick: (e: React.MouseEvent<HTMLElement>) => void;
      testId?: string;
    }
  >;
  triggerSize?: ButtonSize;
  menuProps?: Omit<MenuProps, 'open'>;
  children?:
    | ReactElement
    | (ReactElement | false | null | undefined)[]
    | false
    | null;
  onOpen?: () => void;
  onFocusOut?: () => void;
  tooltipContent?: string;
  shouldShow?: boolean;
  trackingEventName?: EventNames;
  trackingData?: TrackingEventData;
}

const Flyout: FC<FlyoutProps> = ({
  flyoutId = '',
  trigger,
  children,
  menuProps,
  onOpen = noop,
  onFocusOut = noop,
  tooltipContent,
  shouldShow = true,
  trackingEventName,
  trackingData,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { track } = useTracking();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    onOpen();
    if (trackingData) {
      track(trackingEventName ?? EventNames.ThreeDotMenuClick, trackingData);
    }
  };

  const handleClose = (_: {}, reason?: 'backdropClick' | 'escapeKeyDown') => {
    setAnchorEl(null);
    if (reason) {
      onFocusOut();
    }
  };

  const open = shouldShow && Boolean(anchorEl);

  if (React.Children.toArray(children).length === 0) {
    return null;
  }

  return (
    <Tooltip
      disabled={Boolean(anchorEl) || !tooltipContent}
      content={
        <Text kind={TextKind.BodyS} color={TextColor.Contrast}>
          {tooltipContent}
        </Text>
      }
    >
      <div data-testid="flyout">
        {trigger &&
          React.cloneElement(trigger, {
            onClick: (e: React.MouseEvent<HTMLElement>) => {
              if (trigger.props.onClick) {
                trigger.props.onClick(e);
              }
              return handleClick(e);
            },
            'aria-controls': `flyout-menu-${flyoutId}`,
            'aria-haspopup': 'true',
            testId: `flyout-menu-trigger-${flyoutId}`,
          })}
        <StyledMenu
          id={`flyout-menu-${flyoutId}`}
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          disableScrollLock
          {...menuProps}
        >
          {/* This div is required for the forwardRef to work */}
          <div>
            {/* This makes all FLyoutItem children close the menu on click,
            we need to check .type.name so we don't inadvertently pass a react
            prop to HTML dom elements which causes an error */}
            {React.Children.map(children, (child) =>
              child
                ? React.cloneElement(child, {
                    ...((child.type as { name: string }).name ===
                    FlyoutItem.name
                      ? { handleCloseMenu: handleClose }
                      : {}),
                  })
                : null,
            )}
          </div>
        </StyledMenu>
      </div>
    </Tooltip>
  );
};

export default Flyout;
