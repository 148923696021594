import React from 'react';
import { faCog, faRightFromBracket } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { useSelfQuery } from '../../../graphql/types';
import useLogout from '../../../hooks/useLogout';
import useTracking, { TrackingEventData } from '../../../hooks/useTracking';
import { EventCategories, EventNames } from '../../../utils/trackingEvents';
import Avatar from '../Avatar';
import { LinkButton } from '../Button/LinkButton';
import Flyout, { FlyoutItem } from '../Flyout';
import { LinkDecoration } from '../Link';
import Text, { TextColor, TextKind } from '../Text';
import BackButton from './BackButton';
import { FitNavigation } from './FitNavigation';

export const StyledHeader = styled(AppBar)`
  height: 52px;
  &.MuiPaper-root {
    z-index: 2;
  }
  & .MuiToolbar-regular {
    height: 52px;
    min-height: 52px;
  }
  &.MuiAppBar-colorPrimary {
    background-color: black;
    color: white;
  }
`;

const StyledToolbar = styled(Toolbar)`
  justify-content: space-between;
  &.MuiToolbar-gutters {
    padding: 0 16px;
  }
`;

const FlyoutMenuIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`;

interface HeaderProps extends React.PropsWithChildren {
  showNavigation?: boolean;
  backEventData?: TrackingEventData;
}

export const Header: React.FC<HeaderProps> = ({
  showNavigation,
  backEventData,
}) => {
  const { track } = useTracking();
  const navigate = useNavigate();

  const { data } = useSelfQuery({
    fetchPolicy: 'cache-first',
  });

  const user = data?.self;

  const logout = useLogout();

  const handleFlyoutOpen = () => {
    track(EventNames.AvatarClick, {
      event_category: EventCategories.Profile,
    });
  };

  const flyoutMenuItems = (
    <>
      <FlyoutItem
        onClick={() => {
          track(EventNames.MenuItemSelect, {
            event_category: EventCategories.Settings,
            event_label: 'Settings',
          });
          navigate('/app/settings/availability/');
        }}
        testId="settings-button"
      >
        <FlyoutMenuIcon icon={faCog} />
        Settings
      </FlyoutItem>
      <FlyoutItem
        onClick={() => {
          track(EventNames.MenuItemSelect, {
            event_category: EventCategories.Settings,
            event_label: 'Log out',
          });
          logout();
        }}
        testId="logout-button"
      >
        <FlyoutMenuIcon icon={faRightFromBracket} />
        Log out
      </FlyoutItem>
    </>
  );

  if (!user?.coachData?.type) {
    return null;
  }

  return (
    <StyledHeader position="sticky" elevation={0}>
      <StyledToolbar>
        <BackButton eventData={backEventData} />

        {showNavigation && <FitNavigation coachType={user.coachData.type} />}

        {user && user.firstName && (
          <Flyout
            flyoutId="Header"
            trigger={
              <LinkButton
                decoration={LinkDecoration.None}
                onClick={handleFlyoutOpen}
              >
                <Text
                  color={TextColor.Contrast}
                  kind={TextKind.BodyM}
                  data-testid="logged-in-user-name"
                >
                  {user.firstName}
                </Text>
                <Avatar size={36} user={user} />
              </LinkButton>
            }
            menuProps={{
              sx: { marginTop: '10px' },
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
            }}
          >
            {flyoutMenuItems}
          </Flyout>
        )}
      </StyledToolbar>
    </StyledHeader>
  );
};
